var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.blockContent.replace(
        /https:\/\/parkos/g,
        `https://${_vm.$paths.urlEnvPrefix}parkos`
      )
    )}}),_vm._v(" "),(_vm.slug === 'footer_about')?_c('ul',[(_vm.aboutPageLink)?_c('li',{staticClass:"text-white text-[14px] xs:text-base",attrs:{"id":"dynamic-placeholder"},on:{"click":function($event){return _vm.sendEvent(
          'about_us_Click',
          'Action Tracking',
          'Hom-Remove-links-at-the-header'
        )}}},[_c('a',{attrs:{"href":_vm.aboutPageLink}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n          "+_vm._s(_vm.aboutPageTitle)+"\n        ")])])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }